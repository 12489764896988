import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import courseHeaderGif from '../images/lp-course-header-scroll.gif'
import inkdocScrollGif from '../images/lp-inkdoc-scroll.gif'
import Arrow from 'react-arrow'

const learningpathway = ({data}) => (
<Layout>
    <SEO title="Learning Pathways Learner Experience" />
    <div className="Hero">
      <div className="HeroImage">
        <Img fluid={data.LearningPathwayHero.childImageSharp.fluid} alt="Learning Pathways Hero Image" />
      </div>
    </div>

    <div className="PageContent">
        <h1>Learning Pathways Learner Experience</h1>
        <h4>Time: Fall 2018 - Spring 2019</h4>

        <h2>Background</h2>
        <p><a href="https://www.inkling.com/products/learning-pathways/">Learning Pathways</a> is a new product line Inkling started developing in 2018, it extends its core product Knowledge Library to solve the need for restaurant and retail customers for a streamlined on-the-job training experience. Learning Pathway aimed to improve retention and employee satisfaction by providing a single place to guide learners through training courses and link them to all the resources they need.</p> 
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayPersona.childImageSharp.fluid} alt="Learning Pathways Persona" />
        </div>
        <p>Learning Pathways has three personas, and the main user story for each persona is:</p> 
        <ul className="list">
            <li>As an author I can create, edit and distribute training courses to the field. </li>
            <li>As a field manager I can assign courses to my team and track their training progresses.</li>
            <li>As a learner I can access my new hire training materials and understand my training progress.</li>
        </ul>
        <p>I was the lead designer working on the learner experience project and the collaborator to two other designers tackling the other personas.</p>

        <h2>Jobs-To-Be-Done & MVP Scope</h2>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayJTBD.childImageSharp.fluid} alt="Learning Pathways Jobs-to-Be-Done" />
        </div>
        <p>From early research we learned there are different job roles doing similar work cross restaurant industry. From example, a general manager from restaurant A may doing the same job as a store manager at restaurant B. Our team decided to use Jobs-To-Be-Done framework to further align the persona needs. </p>
        <p>As of the learner side Course Module, it needs to incorporate two personas - a learner who's job is to learn and complete the course and a training supervisor (who might also be the manager) to teach and verify the training. Learners also need to have the access to Training Dashboard to view assigned courses and refer back completed courses if need.</p>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayScope.childImageSharp.fluid} alt="Learning Pathways Scope Wireframes" />
        </div>
        <p>The MVP of learner dashboard has two sections - active courses and compeleted courses. A course module has 3 types of steps, an Inkdoc page step which leverages Inkling's Knowledge Library product, an external link step and an activity/task step. There is also a mini report as the last step of a course to allow the supervisor/manager to verify and sign-off.  </p>

        <h2>Concept Testing</h2>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayConcept.childImageSharp.fluid} alt="Learning Pathways Concept" />
        </div>
        <p>We talked to 4 restaurant managers(non customers) and 10 Inkling customers with concept prototypes. This round of research aimed to dig deeper into workflow considerations and requirements for conducting new hire training. They also helped validate the project scope and received feedback for solution design.</p>
        <h3>The checklist metaphor resonates.</h3>
        <p>People responded well to the list layout, to them it's easy to follow, especially with gating applied. "It walks you through what you need and not having to call your manager over all the time." People also like the way to check off a completed step which is similar to the way they used to do with paper binders. </p>
        <h3>Gating, yet flexible.</h3>
        <p>People see the value of gating, it makes learning easy to follow the way curriculum is designed. However some concerned the restriction may not always help in real world situations. Some managers prefer to be flexible with training steps based on time need, training staff availability, etc. </p>
        <p><dfn> Product recommendations:</dfn> make course gating a toggle in settings.</p>
        <h3>Step level sign-off needs.</h3>
        <p>Many restaurants' new hire training requires on-the-job training and demonstration with the supervisor right after learning from the content. For example, the learner is asked to demonstrate how to make a burger to the supervisor after watching the video. Thus the supervisor is responsible to verify that the learner is qualified with the skill.  </p>
        <p><dfn> Product recommendations:</dfn> add trainer sign-off option on activity step.</p>
        <h3>Double verification is essential. </h3>
        <p>One of the top turnover reasons in restaurant industry is employees poor training quality. It is reported employees leave because they didn't feel they were trained properly to do their job well. We heard many store managers say the importance of verification by both learner and trainer to acknowledge then verify.  </p>
        <p><dfn> Product recommendations:</dfn> add double verification at course sign-off step.</p>

        <h2>Iterations & Usability Testing</h2>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayProgress.childImageSharp.fluid} alt="Learning Pathways Course Iterations" />
        </div>
        <p>I then iterate the design based on concept testing takeaways, I also created a new InVision prototype with high-fidelity mocks for usability testing. The goal is to identify the outstanding usability gaps and understand how the solution can be used in the real world.</p>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayTesting.childImageSharp.fluid} alt="Learning Pathways Usability Testing" />
        </div>
        <p>This time we invited 5 frontline restaurant/retail employees came into Inkling's office. During the testing sessions, the participants went through a mock training scenario using the learner experience prototype. Participants went through a flow that involved reading an Inkdoc, going to an external link, performing on-the-job activities, and getting both step level and course level sign-offs.</p>
        <p>Below are some design updates based on usability testing feedback.</p>
        <h3>Add the ability for a user to “Mark Complete” at the bottom of an Inkdoc step.</h3>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayButtonChange.childImageSharp.fluid} alt="Learning Pathways Design Update - Button" />
        </div>
        <p>It was not always immediately obvious to users that “mark complete” was at the top. We often saw them scrolling through the content a few times before they realized. Or, users would go back to the training course detail, at which point they would see it when they re-entered an Inkdoc. When asked, many said they expected (and would prefer) the “mark complete” to be at the bottom of the document.</p>
        <p>In the final design I added a bottom bar with "Mark Complete" button when the user scrolls to the end of the Inkdoc page. </p>
        <h3>Increase the "Read More" character limit in course detail description.</h3>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayCharChange.childImageSharp.fluid} alt="Learning Pathways Design Update - Character Increase" />
        </div>
        <p>Nearly all users did not click “read more” to expand the full description. When asked at the end of the testing sessions if they saw this, users said they did, but admitted that they probably would not tap on it. </p>
        <p>In the final design I increased the course description character limit to 500 before the "read more" button so critical information does not get missed. </p>
        <h3>A new "lock" icon.</h3>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayIconChange.childImageSharp.fluid} alt="Learning Pathways Design Update - Icon" />
        </div>
        <p>Nearly half of the participants thought the lock icon in the prototype looked unlocked, which caused confusion of the gating logic. The team re-visited the icon library in our design system and updated with a new lock icon.  </p>

        <h2>Final Design</h2>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayFinalCourses.childImageSharp.fluid} alt="Learning Pathways Final Design - Course Dashboard" />
        </div>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayFinalDetail.childImageSharp.fluid} alt="Learning Pathways Final Design - Course Detail" />
        </div>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayFinalLink.childImageSharp.fluid} alt="Learning Pathways Final Design - External Link Step" />
        </div>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayFinalSignoff.childImageSharp.fluid} alt="Learning Pathways Final Design - Step SignOff" />
        </div>
        <div className="ImageWrapper">
            <Img fluid={data.LearningPathwayFinalReport.childImageSharp.fluid} alt="Learning Pathways Final Design - Course Report" />
        </div>
        <div className="GifWrapper">
            <img src={courseHeaderGif} alt="Learning Pathways Final Design - Course Header Scroll Animation" maxWidth="780px"/>
        </div>
        <div className="GifWrapper">
            <img src={inkdocScrollGif} alt="Learning Pathways Final Design - Inkdoc Scroll Animation" maxWidth="780px"/>
        </div>

        <h2>What's Next</h2>
        <p>We shipped Learning Pathways Beta to 5 customers in Spring 2019. The team gathered feedback and continued interating and developing more valuable features for Learning Pathways.</p>
    </div>

    <div className="BottomNav">
      <div className="leftNav" >
      <Link to="/autoteams"><Arrow className="arrow"
          direction="left"
          shaftWidth={2}
          shaftLength={8}
          headWidth={10}
          headLength={8}
          />Previous project
        </Link>
      </div>
      <div className="rightNav">
        <Link to="/crmaccounts">Next project<Arrow className="arrow"
          direction="right"
          shaftWidth={2}
          shaftLength={8}
          headWidth={10}
          headLength={8}
          />
        </Link>
        
      </div>
      
    </div>

</Layout>

)

export default learningpathway

export const query = graphql`
    query {
        LearningPathwayHero: file(relativePath: {eq: "lp-hero-image.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayPersona: file(relativePath: {eq: "lp-persona.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayJTBD: file(relativePath: {eq: "lp-jtbd.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayScope: file(relativePath: {eq: "lp-scope-wire.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayConcept: file(relativePath: {eq: "lp-concept.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayProgress: file(relativePath: {eq: "lp-iteration.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayTesting: file(relativePath: {eq: "lp-usability-testing.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayButtonChange: file(relativePath: {eq: "lp-button-change.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayCharChange: file(relativePath: {eq: "lp-char-change.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayIconChange: file(relativePath: {eq: "lp-lock-change.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayFinalCourses: file(relativePath: {eq: "lp-final-courses.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayFinalDetail: file(relativePath: {eq: "lp-final-detail.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayFinalLink: file(relativePath: {eq: "lp-final-link.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayFinalSignoff: file(relativePath: {eq: "lp-final-step-signoff.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        LearningPathwayFinalReport: file(relativePath: {eq: "lp-final-report.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }

}

`